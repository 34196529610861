<template>
  <AppBottomNav :items="items" :back="back" :next="next" />
</template>

<script>
import AppBottomNav from "./AppBottomNav";

export default {
  props: {
    back: String,
    next: String,
  },
  components: {
    AppBottomNav,
  },
  data() {
    return {
      items: [
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Nicht-zählendes Rechnen",
          to: "/nicht-zaehlendes-rechnen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Hintergrundwissen",
          to: "/nicht-zaehlendes-rechnen/hintergrundwissen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Wieso ist Zählendes Rechnen problematisch",
          to: "/nicht-zaehlendes-rechnen/hintergrundwissen/wieso-ist-zaehlendes-rechnen-problematisch",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Teil-Ganzes-Verständnis",
          to: "/nicht-zaehlendes-rechnen/hintergrundwissen/teil-ganzes-verstaendnis",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Aufbau flexibler Rechenstrategien",
          to: "/nicht-zaehlendes-rechnen/hintergrundwissen/aufbau-flexibler-rechenstrategien",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Übergang Diagnose",
          to: "/nicht-zaehlendes-rechnen/uebergang",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Diagnose & Förderung",
          to: "/nicht-zaehlendes-rechnen/diagnose-und-foerderung",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Kompetenzliste",
          to: "/nicht-zaehlendes-rechnen/check",
        },
      ],
    };
  },
};
</script>