<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Hintergrundwissen</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card>
      <v-card-text>
        <p>
          Das Zählen ist eine für den arithmetischen Lernprozess zentrale
          Kompetenz (Moser Opitz, 2020). Zudem gilt Zählen im Sinne der
          Anzahlbestimmung – wie auch das zählende Rechnen – als natürlicher
          Zugang zur Zahl und den Rechenoperationen jüngerer Kinder (Schäfer,
          2005; Häsel-Weide, 2016). Dies hat zur Folge, dass zu Beginn der
          Grundschulzeit erwartbar ist, dass die Schüler:innen Additions- und
          Subtraktionsaufgaben durch Zählen lösen (Gaidoschik, 2010). Dennoch
          sollte das zählende Rechnen unter anderem aufgrund des damit
          verbundenen Zeitaufwands, des Konzentrationsaufwands und der
          Fehleranfälligkeiten nicht als Hauptlösungsstrategie bzw. nicht als
          langfristiges Mittel zur Lösung von Aufgaben verwendet werden
          (Gaidoschik, 2014). Um nichtzählend zu rechnen, benötigen die Kinder
          ein Bündel an Fähigkeiten. Diese setzten sich aus den folgenden
          Aspekten zusammen:
        </p>
        <ul>
          <li>Automatisierte Aufgaben</li>
          <li>Kennen und Nutzen von Rechengesetzen</li>
          <li>Tragfähiges Operationsverständnis</li>
          <li>Tragfähiges Stellenwertverständnis (im größeren Zahlenraum)</li>
          <li>Zahlen- bzw. Aufgabenblick</li>
          <li>
            Verständnis und der Gebrauch der Zahlzerlegungen zwei- und
            mehrstelliger Zahlen
          </li>
        </ul>
        <p>(Schulz, 2014; PriMakom, o.J.; Schäfer, 2005)</p>
        <p>
          Das zählende Rechnen danach also im schulischen Kontext
          weiterzuführen, ist „(vermutlich) weder notwendig noch förderlich“
          (Gaidoschik & Fellmann, 2015, S. 196). Vielmehr sollte das geschickte
          Ableiten von Aufgaben früh gefördert werden. Dies schließt das
          Erarbeiten eines tragfähigen Zahl- und Operationsverständnisses im
          Hinblick auf ein gefestigtes Teil-Ganzes-Verständnis sowie das
          Verständnis und die Anwendung von Rechengesetzen mit ein. Zudem
          gelingt die Ablösung vom zählenden Rechnen deutlich besser, wenn den
          Kindern die Möglichkeit geboten wird, nicht-zählende Rechenstrategien
          entdecken, verstehen und anwenden zu können (Schäfer, 2005). Hierbei
          kann mit Wertschätzung von geschickten Lösungswegen und der
          Thematisierung von Fehlern deutlich gemacht werden, warum alternative
          Strategien zu den zählenden Strategien notwendig sind (Gaidoschik,
          2007). Der gesamte Prozess des gezielten Loslösens vom zählenden
          Rechnen benötigt Zeit und Aufmerksamkeit, um die Schüler:innen
          vollständig hin zu nicht-zählenden Rechner:innen zu fördern
          (Häsel-Weide et al., 2014). Dafür wird sich im Folgenden auf den
          Zahlenraum bis 20 bezogen, denn Ziel sollte es sein, dem zählenden
          Rechnen frühzeitig zu begegnen und ihm durch gezielte Entwicklung von
          nicht-zählenden Strategien vorzubeugen. Dennoch sollte auch im
          erweiterten Zahlenraum darauf geachtet und durch Übertragung der
          angebahnten Strategien aus dem Zahlenraum bis 20 weiterhin dem
          zählenden Rechnen entgegengewirkt werden. Bei verfestigt zählenden
          Kindern in höheren Schuljahren, sollte durch gezielte Thematisierung
          nicht-zählender Strategien ebenso eine Ablösung von zählenden
          Strategien erfolgen.
        </p>
        <br />
        <AppLiteraturNZR />
      </v-card-text>
    </v-card>

    <AppBottomNavNZR
      next="/nicht-zaehlendes-rechnen/hintergrundwissen/wieso-ist-zaehlendes-rechnen-problematisch"
      back="/nicht-zaehlendes-rechnen"
    />
  </div>
</template>

<script>
import AppBottomNavNZR from "@/common/AppBottomNavNZR";
import AppLiteraturNZR from "@/common/AppLiteraturNZR";

export default {
  components: {
    AppBottomNavNZR,
    AppLiteraturNZR,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
