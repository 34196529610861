<style scoped>
div {
  background: rgba(0, 0, 0, 0) !important;
}
</style>

<template>
  <AppButtonCollapse
    icon="mdi-book-open-page-variant-outline"
    messageHidden="Literatur anzeigen"
    messageNotHidden="Literatur verstecken"
    elevation="0"
  >
    <div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>
                Gaidoschik, M. (2007). Rechenschwäche vorbeugen - Erstes
                Schuljahr: Vom Zählen zum Rechnen. ÖBV HPT.
              </td>
            </tr>
            <tr>
              <td>
                Gaidoschik, M. (2010). Wie Kinder rechnen lernen – oder auch
                nicht. Eine empirische Studie zur Entwicklung von
                Rechenstrategien im ersten Schuljahr. Peter Lang.
              </td>
            </tr>
            <tr>
              <td>
                Gaidoschik, M. (2010b). Automatisierendes Üben mit
                rechenschwachen Kindern: Automatisieren von Strategien, nicht
                von Einzelfakten! 20. Symposium mathe 2000.
                http://www.mathematik.uni-dortmund.de/ieem/mathe2000/pdf/Symp20/Workshop%20Gaidoschik%20Homepage.pdf.
              </td>
            </tr>
            <tr>
              <td>
                Gaidoschik, M. (2011). Rechenschwäche – Dyskalkulie. Eine
                unterrichtspraktische Einführung für LehrerInnen und Eltern (6.
                Auflage). Persen.
              </td>
            </tr>
            <tr>
              <td>
                Gaidoschik, M. (2014). Erarbeitung nicht-zählendes
                Rechenstrategien.
                https://pikas.dzlm.de/pikasfiles/uploads/upload/Material/Haus_3_-_Umgang_mit_Rechenschwierigkeiten/FM/Modul_3.3/h3_fm_m3.3_erarbeitung_nicht-zaehlender_rechenstrategien_200429_.pdf
              </td>
            </tr>
            <tr>
              <td>
                Gaidoschik, M. & Fellmann, A. (2015). Zählendes Rechnen im
                1.Schuljahr: (Vermutlich) weder notwendig noch förderlich. In F.
                Caluori, H. Linneweber-Lammerskitten & C. Streit (Hrsg.),
                Beträge zum Mathematikunterricht 2015. WTM-Verlag.
              </td>
            </tr>
            <tr>
              <td>
                Gerster, H.-D. (1996). Vom Fingerrechnen zum Kopfrechnen –
                Methodische Schritte aus der Sackgasse des zählenden Rechnens.
                In G. Eberle & R. Kornmann (Hrsg.), Lernschwierigkeiten und
                Vermittlungsprobleme im Mathematikunterricht an Grund- und
                Sonderschulen. Möglichkeiten der Vermeidung und Überwindung (S.
                137-162). Deutscher Studien Verlag.
              </td>
            </tr>
            <tr>
              <td>
                Häsel-Weide, U. (2016). Vom Zählen zum Rechnen. Springer
                Fachmedien Wiesbaden. https://doi.org/10.1007/978-3-658-10694-2
              </td>
            </tr>
            <tr>
              <td>
                Häsel-Weide, U., Nührenbörger, M., Moser-Opitz, E., & Wittich,
                C. (2017). Ablösung vom zählenden Rechnen: Fördereinheiten für
                heterogene Lerngruppen (2. Auflage). Klett Kallmeyer.
              </td>
            </tr>
            <tr>
              <td>
                Förderzentrum Mathematik-Team (o. J.). Ablösung vom zählenden
                Rechnen.
                https://foerderzentrum.mathematik.tu-dortmund.de/drupal/mathematische-basiskompetenzen/abloesung-vom-zaehlenden-rechnen
              </td>
            </tr>
            <tr>
              <td>
                Ministerium für Schule und Bildung des Landes
                Nordrhein-Westfalen. (2021). Lehrpläne für die Primarstufe in
                Nordrhein-Westfalen.
              </td>
            </tr>
            <tr>
              <td>
                Moser Opitz, E. (2005). Lernschwierigkeiten Mathematik in Klasse
                5 und 8. Eine empirische Untersuchung zu fehlenden
                mathematischen Basiskompetenzen. VHN 74 (2), S. 113-128.
              </td>
            </tr>
            <tr>
              <td>
                Ostad, S. A. (1998). Developmental Differences in Solving Simple
                Arithmetic Word Problems and Simple Number-fact Problems: A
                Comparison of Mathematically Normal and Mathematically Disabled
                Children. Mathematical Cognition, 4(1), 1–19.
              </td>
            </tr>
            <tr>
              <td>
                Padberg, F. & Benz, C. (2021): Didaktik der Arithmetik für
                Lehrerausbildung und Lehrerfortbildung. Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                PIKAS-MI-Team. (o.J.). Zahlvorstellungen.
                https://pikas-mi.dzlm.de/inhalte/zahlvorstellungen-tragfähige-vorstellungen-aufbauen-zr-bis-100/hintergrund/beziehungen
              </td>
            </tr>
            <tr>
              <td>
                PriMakom-Team (o.J.). Zählendes Rechnen.
                https://primakom.dzlm.de/inhalte/zahlen-und-operationen/ablösung-vom-zählenden-rechnen/hintergrund
              </td>
            </tr>
            <tr>
              <td>
                Rathgeb-Schnierer, E. u. Rechtsteiner, Ch. (2018). Rechnen
                lernen und Flexibilität entwickeln. Grundlagen – Förderung –
                Beispiele. Springer.
              </td>
            </tr>
            <tr>
              <td>
                Schäfer, J. (2005). Rechenschwäche in der Eingangsstufe der
                Hauptschule. Verlag Dr. Kovač.
              </td>
            </tr>
            <tr>
              <td>
                Scherer, P., & Moser Opitz, E. (2010). Fördern im
                Mathematikunterricht der Primarstufe. Springer Spektrum.
              </td>
            </tr>
            <tr>
              <td>
                Schipper, W. (2002). Thesen und Empfehlungen zum schulischen und
                außerschulischen Umgang mit Rechenstörungen. Journal für
                Mathematikdidaktik, 23(3/4), 243–261.
              </td>
            </tr>
            <tr>
              <td>
                Schipper, W. (2005). Lernschwierigkeiten erkennen –
                verständnisvolles Lernen fördern. Modul 4 Sinus Transfer.
                http://sinus-transfer-grundschule.de/fileadmin/Materialien/Modul4.pdf
              </td>
            </tr>
            <tr>
              <td>
                Schmidt, S., & Weiser, W. (1982). Zählen und Zahlverständnis von
                Schulanfängern: Zählen und der kardinale Aspekt natürlicher
                Zahlen. Journal für Mathematikdidaktik, 3(3/4), 227–264.
              </td>
            </tr>
            <tr>
              <td>
                Schulz, A. (2014). Fachdidaktisches Wissen von
                Grundschullehrkräften. Springer Fachmedien.
                https://doi.org/10.1007/978-3-658-08693-0
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </AppButtonCollapse>
</template>

<script>
import AppButtonCollapse from "@/common/AppButtonCollapse";

export default {
  name: "AppLiteraturNZR",
  components: {
    AppButtonCollapse,
  },
};
</script>
